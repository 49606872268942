var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.portfolio
    ? _c(
        "div",
        [
          _c("validation-observer", {
            ref: "form",
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var handleSubmit = ref.handleSubmit
                    return [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return handleSubmit(_vm.onSubmit)
                            },
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { attrs: { outlined: "" } },
                            [
                              _vm.portfolio.id
                                ? _c("v-card-title", [
                                    _vm._v(
                                      "Editing " + _vm._s(_vm.portfolio.name)
                                    ),
                                  ])
                                : _c("v-card-title", [
                                    _vm._v("Creating new portfolio"),
                                  ]),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-text-field", {
                                    attrs: { label: _vm.$t("name") },
                                    model: {
                                      value: _vm.portfolio.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.portfolio, "name", $$v)
                                      },
                                      expression: "portfolio.name",
                                    },
                                  }),
                                  _c("v-select", {
                                    attrs: {
                                      items: [
                                        "EUR",
                                        "AUD",
                                        "TRY",
                                        "SEK",
                                        "INR",
                                        "JPY",
                                        "KRW",
                                        "USD",
                                        "NOK",
                                        "CNY",
                                        "CAD",
                                        "THB",
                                        "GBp",
                                        "HKD",
                                        "TWD",
                                        "ZAc",
                                        "IDR",
                                        "BRL",
                                        "PLN",
                                        "SGD",
                                        "ILA",
                                        "QAR",
                                        "SAR",
                                        "DKK",
                                        "CLP",
                                        "MXN",
                                        "CHF",
                                        "NZD",
                                        "MYR",
                                        "ISK",
                                        "ARS",
                                        "CZK",
                                        "GBP",
                                        "HUF",
                                        "AED",
                                        "KWF",
                                        "TND",
                                      ],
                                      label: "Devise",
                                    },
                                    model: {
                                      value: _vm.portfolio.devise,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.portfolio, "devise", $$v)
                                      },
                                      expression: "portfolio.devise",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("upload-error", {
                                attrs: { error: _vm.error },
                              }),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        type: "submit",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("actions.save")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              3463050833
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }