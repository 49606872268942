<template>
  <div v-if="portfolio">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <v-card outlined>
          <v-card-title v-if="portfolio.id">Editing {{ portfolio.name }}</v-card-title>
          <v-card-title v-else>Creating new portfolio</v-card-title>
          <v-card-text>
            <v-text-field v-model="portfolio.name" :label="$t('name')"  />
            <v-select
              v-model="portfolio.devise"
              :items="[
                      'EUR', 'AUD', 'TRY', 'SEK', 'INR', 'JPY', 'KRW', 'USD', 'NOK', 'CNY',
                      'CAD', 'THB', 'GBp', 'HKD', 'TWD', 'ZAc', 'IDR', 'BRL', 'PLN', 'SGD',
                      'ILA', 'QAR', 'SAR', 'DKK', 'CLP', 'MXN', 'CHF', 'NZD', 'MYR', 'ISK',
                      'ARS', 'CZK', 'GBP', 'HUF', 'AED', 'KWF','TND'
                  ]"
              label="Devise"
            ></v-select>
          </v-card-text>
          <upload-error :error="error"/>
          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" color="primary">{{$t('actions.save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      
    </validation-observer>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import formatter from "@/mixins/formatter";

@Component({mixins: [formatter]})
export default class PortfolioForm extends Vue {

  @Prop() portfolioId;

  portfolio =  null;
  error = null;

  mounted() {
    // get or create portfolio object
    if (this.portfolioId) {
      this.$api.get(`portfolio/${this.portfolioId}`).then(j => {
        this.portfolio = j.data;
        console.log(' hfhfhfhf' , this.portfolio);
      });
    } else {
      this.portfolio = {
        name: "",
        devise: "",
      }
      console.log(' hfhfhfhf2' , this.portfolio);
    }
  }

  onSubmit() {
    let id = parseInt(this.portfolioId) || 0;

     const call =  this.$api.post(`portfolio/add-edit?name=${this.portfolio.name}&devise=${this.portfolio.devise}&id=${id}`);

     call.then(j => {
     this.$emit('save', j.data);
     }).catch(err => {
     if (err.response)  this.error = err.response.data;
    //  this.$snack.validationError(err.response.data)
     });
  }

  get rules() {
    return this.$store.state.rules;
  }

}
</script>

<style>
#fileInput {
  display: none;
}
h2 {
  margin: 1em 0;
}
</style>
